import './Main.scss';
import { useState, useEffect } from 'react';
import logo from '../../assets/say.svg';
import arrow from '../../assets/arrow.svg';
import minus from '../../assets/minus.svg';
import plus from '../../assets/plus.svg';

export default function Main() {

    const host = "";

    const [selectedTown, setSelectedTown] = useState('Выбери город');
    const [selectedProduct, setSelectedProduct] = useState("Выбери продукт");
    const [countDay, setCountDay] = useState(1);
    const [selectedType, setSelectedType] = useState('Тип');
    const [currentFas, setCurrentFas] = useState('Фасовка');

    const [isOpenedTowns, setIsOpenedTowns] = useState(false);
    const [isOpenedProducts, setIsOpenedProducts] = useState(false);

    const [weekPayment, setWeekPayment] = useState('?');
    const [monthPayment, setMonthPayment] = useState('?');
    const [bonusPayment, setBonusPayment] = useState('?');

    const [productPrice, setProductPrice] = useState(0);

    const [towns, setTowns] = useState([]);

    const [products, setProducts] = useState([]);

    const [fases, setFases] = useState([]);

    const [types, setTypes] = useState([]);

    const decrementCount = () => {
        // Функция уменьшения значения стейта на 1, если оно больше нуля
        if (countDay > 1) {
            setCountDay(prevCount => prevCount - 1);
        }
    };    

    const incrementCount = () => {
        // Функция увеличения значения стейта на 1
        setCountDay(prevCount => prevCount + 1);
    };

    useEffect(() => {
        const fetchCities = async () => {
          try {
            const response = await fetch(`${host}/api/cities/`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Извлечение и сохранение только имен городов в стейт
            const cityNames = data.map(city => city.name);
            setTowns(cityNames);
          } catch (error) {
            console.error('Error fetching cities:', error);
          }
        };
    
        fetchCities();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
          try {
            const response = await fetch(`${host}/api/cities/`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            
            // Находим объект города по имени
            const selectedCity = data.find(city => city.name === selectedTown);
            if (!selectedCity) {
              throw new Error('Selected town not found in data');
            }
    
            // Получаем все product_name из product_details выбранного города
            const products = selectedCity.product_details.map(product => product.product_name);
            // Устанавливаем полученные продукты в стейт
            setProducts(products);
          } catch (error) {
            console.error('Error fetching cities:', error);
          }
        };
    
        fetchProducts();
    }, [selectedTown]);    
    
    useEffect(() => {
        const fetchFases = async () => {
          try {
            const response = await fetch(`${host}/api/packings/`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Извлечение и сохранение только имен городов в стейт
            const fasesNames = data.map(fas => fas.weight_in_grams);
            setFases(fasesNames);
          } catch (error) {
            console.error('Error fetching cities:', error);
          }
        };
    
        fetchFases();
    }, []);

    useEffect(() => {
        const fetchTypes = async () => {
          try {
            const response = await fetch(`${host}/api/treasure-types/`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Извлечение и сохранение только имен городов в стейт
            const typesNames = data.map(type => type.name);
            setTypes(typesNames);
          } catch (error) {
            console.error('Error fetching cities:', error);
          }
        };
    
        fetchTypes();
    }, []);

    useEffect(() => {
        const calculateSalaryTownProduct = async () => {
            try {
                const response = await fetch(`${host}/api/cities/`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
        
                // Находим объект города по имени
                const selectedCity = data.find(city => city.name === selectedTown);
                if (!selectedCity) {
                    throw new Error('Selected town not found in data');
                }
        
                // Находим объект продукта по его названию
                const selectedProductDetails = selectedCity.product_details.find(product => product.product_name === selectedProduct);
                if (!selectedProductDetails) {
                    throw new Error('Selected product not found in city data');
                }
        
                // Преобразуем цену продукта и значение наценки/скидки в числовой формат
                const productPrice = parseFloat(selectedProductDetails.product_price);
                const value = parseFloat(selectedProductDetails.value);
        
                // Применяем наценку/скидку к цене продукта в зависимости от типа
                let finalPrice;
                if (selectedProductDetails.type === "surcharge" && selectedProductDetails.value_type === "percentage") {
                    finalPrice = Math.round(productPrice * (1 + (value / 100)));
                } else if (selectedProductDetails.type === "markdown" && selectedProductDetails.value_type === "percentage") {
                    finalPrice = Math.round(productPrice * (1 - (value / 100)));
                } else if (selectedProductDetails.type === "surcharge" && selectedProductDetails.value_type === "amount") {
                    finalPrice = Math.round(productPrice + value);
                } else if (selectedProductDetails.type === "markdown" && selectedProductDetails.value_type === "amount") {
                    finalPrice = Math.round(productPrice - value);
                }
        
                // Устанавливаем полученную цену продукта в стейт
                setProductPrice(finalPrice);
        
                // Возвращаем цену продукта для расчетов
                return finalPrice;
            } catch (error) {
                console.error('Error calculating salary per day:', error);
                return null;
            }
        };
    
        calculateSalaryTownProduct();
    }, [selectedProduct, productPrice]);

    const handleResultPerDay = async () => {
        if (selectedTown !== 'Выбери город' && selectedProduct !== "Выбери продукт" && currentFas !== 'Фасовка' && selectedType !== 'Тип') {
            try {
            // Запрос к api/packings/
            const packingsResponse = await fetch(`${host}/api/packings/`);
            if (!packingsResponse.ok) {
                throw new Error('Network response was not ok');
            }
            const packingsData = await packingsResponse.json();
            
            // Находим объект packings с weight_in_grams = currentFas
            const selectedPacking = packingsData.find(packing => packing.weight_in_grams === currentFas);
            if (!selectedPacking) {
                throw new Error('Selected packing not found');
            }
    
            // Применяем наценку/скидку к цене продукта в зависимости от типа
            let tempPrice = productPrice;
            if (selectedPacking.type === "surcharge" && selectedPacking.value_type === "percentage") {
                tempPrice = tempPrice * (1 + (parseFloat(selectedPacking.value) / 100));
            } else if (selectedPacking.type === "markdown" && selectedPacking.value_type === "percentage") {
                tempPrice = tempPrice * (1 - (parseFloat(selectedPacking.value) / 100));
            } else if (selectedPacking.type === "surcharge" && selectedPacking.value_type === "amount") {
                tempPrice = tempPrice + parseFloat(selectedPacking.value);
            } else if (selectedPacking.type === "markdown" && selectedPacking.value_type === "amount") {
                tempPrice = tempPrice - parseFloat(selectedPacking.value);
            }
    
            // Запрос к api/treasure-types/
            const treasureTypesResponse = await fetch(`${host}/api/treasure-types/`);
            if (!treasureTypesResponse.ok) {
                throw new Error('Network response was not ok');
            }
            const treasureTypesData = await treasureTypesResponse.json();
    
            // Находим объект с name равным selectedType
            const selectedTreasureType = treasureTypesData.find(type => type.name === selectedType);
            if (!selectedTreasureType) {
                throw new Error('Selected treasure type not found');
            }
    
            // Применяем наценку/скидку второй раз к цене продукта в зависимости от типа
            if (selectedTreasureType.type === "surcharge" && selectedTreasureType.value_type === "percentage") {
                tempPrice = tempPrice * (1 + (parseFloat(selectedTreasureType.value) / 100));
            } else if (selectedTreasureType.type === "markdown" && selectedTreasureType.value_type === "percentage") {
                tempPrice = tempPrice * (1 - (parseFloat(selectedTreasureType.value) / 100));
            } else if (selectedTreasureType.type === "surcharge" && selectedTreasureType.value_type === "amount") {
                tempPrice = tempPrice + parseFloat(selectedTreasureType.value);
            } else if (selectedTreasureType.type === "markdown" && selectedTreasureType.value_type === "amount") {
                tempPrice = tempPrice - parseFloat(selectedTreasureType.value);
            }
    
            // Устанавливаем полученное значение цены продукта в стейт
            setProductPrice(Math.round(tempPrice));
            setWeekPayment(Math.round(tempPrice) * countDay * 7);
            setMonthPayment(Math.round(tempPrice) * countDay * 30);
            } catch (error) {
            console.error('Error handling result per day:', error);
            }
        } else {
            alert("Заполните все поля!")
        }
    };
    
    const handleInputChange = (e) => {
        const newValue = parseInt(e.target.value);
        if (!isNaN(newValue)) {
            setCountDay(newValue);
        }
    };

    return (
        <div className="Main">
            <div className="wrapper">
                <img src={logo} alt="logo" />
                <h2>Узнай свой доход</h2>
                <div className="content">
                    <div className="selecters">
                        <div className="selecter">
                            <h6>Город, в котором будешь работать</h6>
                            <div className="select" onClick={() => setIsOpenedTowns(!isOpenedTowns)}>
                                <span>{selectedTown}</span>
                                <img 
                                    src={arrow} 
                                    alt="arrow" 
                                    style={{ transform: isOpenedTowns ? 'rotate(0deg)' : 'rotate(180deg)' }}
                                    onClick={() => setIsOpenedTowns(prevState => !prevState)}
                                />
                            </div>
                            {isOpenedTowns && <div className="list">
                                {towns.map((town, index) => (
                                    <div className="el" key={index} onClick={() => {setSelectedTown(town);setIsOpenedTowns(false);setSelectedProduct('Выбери продукт');setIsOpenedProducts(false)}}>
                                        <span>{town}</span>
                                    </div>
                                ))}
                            </div>}
                        </div>
                        <div className="selecter">
                            <h6>Товар, с которым будешь работать</h6>
                            <div className="select" onClick={() => setIsOpenedProducts(!isOpenedProducts)}>
                                <span>{selectedProduct}</span>
                                <img 
                                    src={arrow} 
                                    alt="arrow" 
                                    style={{ transform: isOpenedProducts ? 'rotate(0deg)' : 'rotate(180deg)' }}
                                    onClick={() => setIsOpenedProducts(prevState => !prevState)}
                                />
                            </div>
                            {isOpenedProducts && <div className='list'>
                                {products.map((product, index) => (
                                    <div className='el' key={index} onClick={() => {setSelectedProduct(product);setIsOpenedProducts(false)}}>
                                        <span>{product}</span>
                                    </div>
                                ))}    
                            </div>}
                        </div>
                    </div>
                    <div className="fases">
                        <h2>Выбери фасовку (г)</h2>
                        <div className="fass">
                            {fases.map((fas, index) => (
                                <button key={index} className={currentFas === fas ? 'fas selected' : 'fas'} onClick={() => setCurrentFas(fas)}>
                                    <span>{fas}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="counter">
                        <h6>Кол-во кладов в день</h6>
                        <div className="inputs">
                            <input
                                type="text"
                                value={countDay}
                                onChange={handleInputChange}
                            />
                            <button onClick={decrementCount}><img src={minus} alt="icon" /></button>
                            <button onClick={incrementCount}><img src={plus} alt="icon" /></button>
                        </div>
                    </div>
                    <div className="types">
                        <h6>Выберите тип клада, который будешь делать</h6>
                        <div className="typebtns">
                            {types.map((type, index) => (
                                <button
                                    key={index}
                                    className={selectedType === type ? 'selected' : ''}
                                    onClick={() => setSelectedType(type)}
                                >
                                    {type}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="info">
                        <div className="part">
                            <h4>{weekPayment}</h4>
                            <span>Рублей в неделю</span>
                        </div>
                        <div className="part">
                            <h4>{monthPayment}</h4>
                            <span>Рублей в месяц</span>
                        </div>
                    </div>
                    <div className="bonus">
                        <h5>Недельная премия за кол-во кладов</h5>
                        <span>{bonusPayment} р.</span>
                    </div>
                    <button onClick={handleResultPerDay}>Рассчитать доход</button>
                </div>
            </div>
        </div>
    )
}
